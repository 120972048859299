import { GeoJSONPoint } from '../../../common/models/geo.model'

export enum ResultType {
  // GRID = 'LOCATOR_GRID',
  SUMMIT = 'SUMMIT',
  PARK = 'PARK',
  CQ = 'CQ_ZONE',
  ITU = 'ITU_ZONE',
  COMMUNE = 'COMMUNE',
  COUNTY = 'COUNTY',
  VOIVODESHIP = 'VOIVODESHIP',
  ISLAND = 'ISLAND',
  COUNTRY = 'COUNTRY',
}

export type SearchResult = {
  type: string // TODO
  key: string
  name: string
  country: string
  count?: number
  geo?: GeoJSONPoint
  disabled: boolean
}

export type PointResult = {
  type: ResultType
  reference: string
  order: number
  name: string
  coords: GeoJSONPoint
}
