import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getSelectedObject } from './aside.selectors'
import { SelectedObjectType } from './aside.model'
import { AsideContentContainer, LoadingContainer, Row } from './Aside.styles'
import { getParkRelations, getParkDetails, getSummitsWithin, getParksComments, getParkDetailsCounties, getParkDetailsCommunes } from '../parks/parks.slice'
import { isLoadingSelector, selectedParkCommentsSelector } from '../parks/parks.selectors'
import { ParkDetailsComponent } from './components/park/ParkDetails.component'
import { ParkActivationsComponent } from './components/park/ParkActivations.component'
import { ParkRelationsComponent } from './components/park/ParkRelations.component'
import { ParkActivationSpotsComponent } from './components/park/ParkActivationSpots.component'
import CircularProgress from '@mui/material/CircularProgress'
import { getVoivodeshipDetails, getVoivodeshipsComments } from '../voivodeships/voivodeships.slice'
import { VoivodeshipDetailsComponent } from './components/voivodeship/VoivodeshipDetails.component'
import { SummitDetailsComponent } from './components/summit/SummitDetails.component'
import { getRelatedParks, getSummitDetails, getSummitsComments, getSummitsNearby } from '../summits/summits.slice'
import { SummitActivationsComponent } from './components/summit/SummitActivations.component'
import { SummitRelatedParksComponent } from './components/summit/SummitRelatedParks.component'
import { SummitNearbySummitsComponent } from './components/summit/SummitNearbySummits.component'
import { ParkSummitWithinComponent } from './components/park/ParkSummitsWithin.component'
import { PointDetailsComponent } from './components/point/PointDetails.component'
import { IconsComponent } from './components/Icons.component'
import { toggleFlag } from '../flags/flags.slice'
import { FlagType } from '../flags/flags.model'
import { AppDispatch } from '../../../store'
import { selectedVoivodeshipCommentsSelector } from '../voivodeships/voivodeships.selectors'
import { CommentsListComponent } from './components/comment/CommentsList.component'
import { getCountryComments } from '../countries/countries.slice'
import { selectedCountryCommentsSelector } from '../countries/countries.selectors'
import { selectedSummitCommentsSelector } from '../summits/summits.selectors'
import { ParkDetailsCountiesComponent } from './components/park/ParkDetailsCounties.component'
import { ParkDetailsCommunesComponent } from './components/park/ParkDetailsCommunes.component'

export const AsideContent = () => {
  const dispatch = useDispatch<AppDispatch>()
  const selectedObject = useSelector(getSelectedObject)

  const isCountry = selectedObject?.type === SelectedObjectType.COUNTRY
  const countryComments = useSelector(selectedCountryCommentsSelector)

  const isVoivodeship = selectedObject?.type === SelectedObjectType.VOIVODESHIP
  const isLoadingVoivodeshipDetails = useSelector(isLoadingSelector)
  const voivodeshipComments = useSelector(selectedVoivodeshipCommentsSelector)

  const isPark = selectedObject?.type === SelectedObjectType.PARK
  const isLoadingParkDetails = useSelector(isLoadingSelector)
  const parkComments = useSelector(selectedParkCommentsSelector)

  const isSummit = selectedObject?.type === SelectedObjectType.SUMMIT
  const summitComments = useSelector(selectedSummitCommentsSelector)

  const isPoint = selectedObject?.type === SelectedObjectType.POINT

  useEffect(() => {
    if (!selectedObject) return
    const { reference } = selectedObject

    if (selectedObject && isCountry) {
      dispatch(getCountryComments(reference))
    }
    if (selectedObject && isVoivodeship) {
      dispatch(getVoivodeshipDetails(reference))
      dispatch(getVoivodeshipsComments(reference))
    }
    if (selectedObject && isPark) {
      dispatch(getParkDetails(reference))
      dispatch(getParkRelations(reference))
      dispatch(getSummitsWithin(reference))
      dispatch(getParksComments(reference))
      dispatch(getParkDetailsCounties(reference))
      dispatch(getParkDetailsCommunes(reference))
    }
    if (selectedObject && isSummit) {
      dispatch(getSummitDetails(reference))
      dispatch(getRelatedParks(reference))
      dispatch(getSummitsNearby(reference))
      dispatch(getSummitsComments(reference))
    }
  }, [selectedObject, dispatch, isPark, isVoivodeship, isSummit, isCountry])

  if (!selectedObject) return

  const handleToggleClick = (type: FlagType) => {
    const { reference, type: objectType } = selectedObject

    dispatch(
      toggleFlag({
        type,
        reference,
        object: objectType,
      })
    )
  }

  return (
    <AsideContentContainer>
      <div className="details-background" style={{ backgroundImage: selectedObject?.type === SelectedObjectType.PARK ? 'url(/pota-logo-10.png)' : '' }}>
        {(isLoadingParkDetails || isLoadingVoivodeshipDetails) && (
          <LoadingContainer>
            <CircularProgress />
          </LoadingContainer>
        )}
        <Row>
          <h1>
            {selectedObject.type.charAt(0)}
            {selectedObject.type.toLowerCase().slice(1)} {selectedObject.reference}
          </h1>
        </Row>
        <h2>{selectedObject.name}</h2>
        {!isPoint && <IconsComponent reference={selectedObject.reference} objectType={selectedObject.type} onToggleClick={handleToggleClick} />}
        {isCountry && (
          <>
            <div style={{ marginBottom: 5 }}></div>
            <CommentsListComponent comments={countryComments} />
          </>
        )}
        {isVoivodeship && (
          <>
            <VoivodeshipDetailsComponent />
            <CommentsListComponent comments={voivodeshipComments} />
          </>
        )}
        {isPark && (
          <>
            <ParkDetailsComponent />
            <ParkActivationsComponent />
            <ParkRelationsComponent />
            <ParkDetailsCountiesComponent />
            <ParkDetailsCommunesComponent />
            <ParkSummitWithinComponent />
            <CommentsListComponent comments={parkComments} />
            <ParkActivationSpotsComponent />
          </>
        )}
        {isSummit && (
          <>
            <SummitDetailsComponent />
            <SummitActivationsComponent />
            <SummitRelatedParksComponent />
            <SummitNearbySummitsComponent />
            <CommentsListComponent comments={summitComments} />
          </>
        )}
        {isPoint && (
          <>
            <PointDetailsComponent />
          </>
        )}
      </div>
    </AsideContentContainer>
  )
}
