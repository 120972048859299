import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { selectedParkDetailsCountiesSelector } from '../../../parks/parks.selectors'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import { ParkRelation, ParkRelationType } from '../../../parks/parks.model'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { InfoChip } from '../../../../../common/components'

const TableRowStyled = styled(TableRow)`
  &:hover {
    background-color: #ececec;
  }
`

export const ParkDetailsCountiesComponent = () => {
  const data = useSelector(selectedParkDetailsCountiesSelector)
  if (!data?.length) return

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <b style={{ marginRight: '0.5rem' }}>Counties</b>
        <InfoChip text={data.length.toString()} />
      </AccordionSummary>
      <AccordionDetails sx={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}>
        <Table aria-label="table" size="small">
          <TableBody>
            {data.map((relation: ParkRelation) => {
              return (
                <TableRowStyled key={relation.reference}>
                  <TableCell>
                    <b>{relation.reference}</b>, Reference type: <b>{Object.values(ParkRelationType)[Object.keys(ParkRelationType).indexOf(relation.type)]}</b>
                    <br />
                    {relation.name}
                  </TableCell>
                </TableRowStyled>
              )
            })}
          </TableBody>
        </Table>
      </AccordionDetails>
    </Accordion>
  )
}
